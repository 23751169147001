import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { Col, Form, Row } from "react-bootstrap";
import Markdown from "../../Components/Markdown/Markdown";
import AddForm from "../../Components/AddForm";
import SubmitWaiting from "../../Components/SubmitWaiting/SubmitWaiting";
import useApi from "../../Hooks/useApi";
import * as api from "../../api/getapi";
import PopUpModal from "../../Components/PopUpModal";
import AddComponent from "../../Components/Component/AddComponent";
import InputControl from "../../Components/InputFields/InputControl";
import DropdownControl from "../../Components/InputFields/DropdownControl";
import { dateTime } from "../../Features/dateTime";

const TvMonitorsFormPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { mode, id } = useParams();

  const getComponentApi = useApi(api.getComponent);
  const componentApi = useApi(api.getComponentData);

  const [validated, setValidated] = useState(false);

  const [title, setTitle] = useState("");
  const [modelName, setModelName] = useState("");
  const [launchPrice, setLaunchPrice] = useState("");
  const [releaseDate, setReleaseDate] = useState(null);
  const [type, setType] = useState({ value: "Monitor", label: "Monitor" });

  const [brand, setBrand] = useState("");
  const [color, setColor] = useState([]);
  const [screenType, setScreenType] = useState("");
  const [ports, setPorts] = useState([]);
  const [speaker, setSpeaker] = useState("");
  const [screenSize, setScreenSize] = useState("");
  const [refreshRate, setRefreshRate] = useState("");
  const [resolution, setResolution] = useState("");
  const [displayType, setDisplayType] = useState("");
  const [webcam, setWebcam] = useState({ value: false, label: "No" });
  const [smartTv, setSmartTv] = useState({ value: false, label: "No" });
  const [operatingSystem, setOperatingSystem] = useState("");
  const [certification, setCertification] = useState({
    value: false,
    label: "No",
  });
  const [description, setDescription] = useState("");
  const [descriptionText, setDescriptionText] = useState("");

  const [submiting, setSubmiting] = useState(false);
  const [submited, setSubmited] = useState(false);
  const [failed, setFailed] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");

  const [showAddComponentModal, setShowAddComponentModal] = useState(false);
  const [componentData, setComponentData] = useState(false);
  const [refresh, toggle] = useState(false);
  const [refreshDropdown, setRefreshDropdown] = useState("");
  const [isModalLoading, setIsModalLoading] = useState("");

  const getDropdownOptions = (component, filter) => {
    let timer = null;
    const comCall = (search) => {
      return componentApi.request(component, filter, true, undefined, undefined, search);
    };
    return (inputText) => {
      if (timer) {
        clearTimeout(timer);
      }
      return new Promise((resolve, reject) => {
        if (inputText.length > 0) {
          timer = setTimeout(() => {
            comCall(inputText)
              .then((data) => {
                const getData = data?.data?.success?.data.map(({ id, label }) => ({
                  value: id,
                  label: String(label),
                }));
                resolve(getData);
              })
              .catch((err) => {
                reject(err);
              });
          }, 200);
        } else {
          timer = null;
          comCall()
            .then((data) => {
              const getData = data?.data?.success?.data.map(({ id, label }) => ({
                value: id,
                label: String(label),
              }));
              resolve(getData);
            })
            .catch((err) => {
              reject(err);
            });
        }
      });
    };
  };
  useEffect(() => {
    if (id) {
      axios.post(process.env.REACT_APP_URL_API_DATA + "/model/leds/" + id).then((res) => {
        if (res.data?.code === 200) {
          setErrorMessage("");
          const resData = res?.data?.success?.data;
          setTitle(resData.title);
          setModelName(resData.model_name);
          setBrand({
            value: resData.brand_id,
            label: resData.brand_name,
          });
          setLaunchPrice(resData.launch_price ? +resData.launch_price : "");
          if (resData.release_date) {
            let objectDate = dateTime(resData.release_date);
            let day = objectDate.getDate();
            let month = objectDate.getMonth() + 1;
            let year = objectDate.getFullYear();
            setReleaseDate(`${year}-${month.toString().padStart(2, "0")}-${day.toString().padStart(2, "0")}`);
          }
          setType(resData.tv_monitor_type === "Monitor" ? { value: "Monitor", label: "Monitor" } : { value: "TV", label: "TV" });
          setColor(
            resData.colors_available.map(({ id, label }) => ({
              value: id,
              label: label,
            })),
          );
          setPorts(resData.ports.split(",").map((port) => ({ value: port, label: port })));
          setSpeaker({ value: resData.sp_id, label: resData.speaker });
          setResolution({
            value: resData.rs_id,
            label: resData.resolution,
          });
          setRefreshRate({
            value: resData.rr_id,
            label: resData.refresh_rate,
          });
          setScreenSize({
            value: resData.ss_id,
            label: resData.screen_size,
          });
          setScreenType({
            value: resData.st_id,
            label: resData.screen_type,
          });
          setDisplayType({
            value: resData.d_t_id,
            label: resData.display_type,
          });
          setWebcam(
            +resData.webcam === 1
              ? {
                  value: true,
                  label: "Yes",
                }
              : {
                  value: false,
                  label: "No",
                },
          );
          setSmartTv(
            +resData.smart_tv === 1
              ? {
                  value: true,
                  label: "Yes",
                }
              : {
                  value: false,
                  label: "No",
                },
          );
          setOperatingSystem({
            value: resData.os_id,
            label: resData.os,
          });
          setCertification(
            +resData.tv_certification === 1
              ? {
                  value: true,
                  label: "Yes",
                }
              : {
                  value: false,
                  label: "No",
                },
          );
          setDescription(resData.description);
          setDescriptionText(resData.description);
        } else {
          setErrorMessage(res.data?.error?.message);
        }
      });
    }

    return () => {};
  }, [id]);

  useEffect(() => {
    setTitle(`${brand ? brand.label.trim() : ""} ${modelName.trim()} ${screenSize ? screenSize.label.trim() : ""} ${refreshRate ? refreshRate.label.trim() : ""}`);
  }, [modelName, brand, screenSize, refreshRate]);

  const callForComponentData = async (table_name) => {
    setIsModalLoading(true);
    setShowAddComponentModal(true);
    const tableComponentResponse = await getComponentApi.request(table_name);
    if (tableComponentResponse?.data?.code === 200) {
      setComponentData(tableComponentResponse?.data?.success?.data);
      setRefreshDropdown(table_name);
    }
    setIsModalLoading(false);
  };

  useEffect(() => {
    setRefreshDropdown("");
  }, [refresh]);

  const submitForm = async (e) => {
    try {
      e.preventDefault();
      const form = e.currentTarget;
      if (form.checkValidity() === false) {
        e.preventDefault();
        e.stopPropagation();
      }
      setValidated(true);
      let pass = true;
      if (smartTv.value) {
        if (Object.keys(operatingSystem).length > 0 && Object.keys(certification).length > 0) {
          pass = true;
        } else {
          pass = false;
        }
      }
      if (
        title.trim().length > 0 &&
        modelName.trim().length > 0 &&
        launchPrice?.toString().length > 0 &&
        launchPrice &&
        // +launchPrice > 0 &&
        releaseDate &&
        descriptionText.trim().length > 0 &&
        Object.keys(brand).length > 0 &&
        Object.keys(color).length > 0 &&
        Object.keys(ports).length > 0 &&
        Object.keys(speaker).length > 0 &&
        Object.keys(resolution).length > 0 &&
        Object.keys(refreshRate).length > 0 &&
        Object.keys(screenSize).length > 0 &&
        Object.keys(screenType).length > 0 &&
        Object.keys(displayType).length > 0 &&
        Object.keys(webcam).length > 0 &&
        pass
      ) {
        setSubmiting(true);
        const obj = {
          model_title: title.trim(),
          model_name: modelName.trim(),
          release_date: releaseDate,
          launch_price: launchPrice,
          tv_monitor_type: type.value,
          category_id: 4,
          category_name: "TV / Monitor",
          brand_id: brand.value,
          brand_name: brand.label,
          colors_available: color.map((obj) => ({
            id: obj.value,
            label: obj.label,
          })),
          screen_size: screenSize.label,
          ss_id: screenSize.value,
          ports: ports.map((obj) => obj.label).join(","),
          speaker: speaker.label,
          sp_id: speaker.value,
          screen_type: screenType.label,
          st_id: screenType.value,
          refresh_rate: refreshRate.label,
          rr_id: refreshRate.value,
          resolution: resolution.label,
          rs_id: resolution.value,
          display_type: displayType.label,
          d_t_id: displayType.value,
          webcam: webcam.value,
          smart_tv: smartTv.value,
          os: operatingSystem.label,
          os_id: operatingSystem.value,
          tv_certification: certification.value,
          description: description,
        };
        let modelRes;
        if (mode === "edit" && id) {
          modelRes = await axios.patch(process.env.REACT_APP_URL_API_DATA + "/model/update/" + id, obj, { withCredentials: true });
        } else if (mode === "duplicate" && id) {
          modelRes = await axios.post(process.env.REACT_APP_URL_API_DATA + "/model/duplicate/" + id, obj, { withCredentials: true });
        } else {
          modelRes = await axios.post(process.env.REACT_APP_URL_API_DATA + "/model/add", obj, { withCredentials: true });
        }
        if (modelRes) {
          setSubmited(true);
          setTimeout(() => {
            setSubmiting(false);
            setSubmited(false);
            navigate("/tv-monitors?page=" + location.state.page);
          }, 2000);
        }
      }
    } catch (err) {
      setFailed(true);
      setTimeout(() => {
        setSubmiting(false);
        setFailed(false);
      }, 2000);
    }
  };

  return (
    <>
      <SubmitWaiting submiting={submiting} submited={submited} failed={failed} />
      <AddForm>
        <div className="d-flex justify-content-start">
          <button className="backFormButton" onClick={() => navigate(-1)}>
            <span>Back</span>
          </button>
        </div>
        <div className="d-flex justify-content-center mx-4 mb-2">
          <h2 className="text-secondary text-capitalize">{mode} TV / Monitor</h2>
        </div>
        <Form noValidate validated={validated} onSubmit={submitForm}>
          <Row>
            <InputControl
              as={Col}
              lg="4"
              md="6"
              sm="12"
              label={"title"}
              type={"text"}
              placeholder={"Enter Title"}
              value={title}
              onChange={(e) => {
                setTitle(e.target.value);
              }}
              disabled={true}
              required
              feedback={{ type: "invalid", message: "Please Enter the title." }}
            />
            <InputControl
              as={Col}
              lg="4"
              md="6"
              sm="12"
              type={"text"}
              label={"Model Name"}
              placeholder={"Enter Model Name"}
              value={modelName}
              important={true}
              onChange={(e) => {
                setModelName(e.target.value);
              }}
              required
              feedback={{ type: "invalid", message: "Please Enter Model name." }}
            />
            <InputControl
              as={Col}
              lg="4"
              md="6"
              sm="12"
              type={"number"}
              label={"Launch Price"}
              placeholder={"Enter Launch Price"}
              value={launchPrice}
              important={true}
              onChange={(e) => {
                const format = /^[0-9]*$/;
                const [first, second] = e.target.value.split(".");
                let endResult;
                let firstPart;
                if (format.test(first)) {
                  if (first?.length < 8) {
                    firstPart = first;
                  } else {
                    firstPart = first.slice(0, 8);
                  }
                }
                if (e.target.value.includes(".")) {
                  if (second?.length > 0) {
                    if (format.test(second)) {
                      if (second?.length < 3) {
                        endResult = [firstPart, second].join(".");
                      } else {
                        endResult = [firstPart, second.slice(0, 2)].join(".");
                      }
                    }
                  } else {
                    endResult = firstPart + ".";
                  }
                } else {
                  if (firstPart && format.test(firstPart)) {
                    endResult = firstPart;
                  } else {
                    endResult = "";
                  }
                }
                setLaunchPrice(endResult);
              }}
              required
              feedback={{ type: "invalid", message: "Please Enter Launch Price." }}
            />
            <InputControl
              as={Col}
              lg="4"
              md="6"
              sm="12"
              type={"date"}
              label={"Release Date"}
              placeholder={"Enter Release Date"}
              value={releaseDate}
              important={true}
              onChange={(e) => {
                setReleaseDate(e.target.value);
              }}
              required
              feedback={{ type: "invalid", message: "Please Enter Release Date." }}
            />
            <DropdownControl
              as={Col}
              lg="4"
              md="6"
              sm="12"
              isSearchable={false}
              label={"Type"}
              important={true}
              cache={true}
              options={() => {
                return new Promise((resolve, reject) => {
                  resolve([
                    {
                      value: "Monitor",
                      label: "Monitor",
                    },
                    {
                      value: "TV",
                      label: "TV",
                    },
                  ]);
                });
              }}
              onChange={(e) => {
                setType(e);
              }}
              value={type}
              required={true}
              feedback={{ type: "invalid", message: "Please select Type." }}
              isInvalid={validated && !type}
            />
            <DropdownControl
              as={Col}
              lg="4"
              md="6"
              sm="12"
              key={"brand" === refreshDropdown ? refreshDropdown : "brand-key"}
              noOptionsMessage={() => (
                <div className="dropdown-no-option-action" onClick={() => callForComponentData("brand")}>
                  Option is not available.
                  <br />
                  <span
                    style={{
                      color: "blue",
                      fontStyle: "italic",
                    }}
                  >
                    Click to Add
                  </span>
                </div>
              )}
              label={"Brand"}
              important={true}
              cache={true}
              options={getDropdownOptions("brand", {
                is_led: true,
              })}
              // onInputChange={(newInput) => {
              //   const inputValue = newInput.replace(/\B\s+\B/g, "");
              //   return inputValue;
              // }}
              onChange={(e) => {
                setBrand(e);
              }}
              value={brand}
              required={true}
              feedback={{ type: "invalid", message: "Please select brand." }}
              isInvalid={validated && !brand}
            />

            <DropdownControl
              as={Col}
              lg="4"
              md="6"
              sm="12"
              key={"color" === refreshDropdown ? refreshDropdown : "color-key"}
              noOptionsMessage={() => (
                <div className="dropdown-no-option-action" onClick={() => callForComponentData("color")}>
                  Option is not available.
                  <br />
                  <span
                    style={{
                      color: "blue",
                      fontStyle: "italic",
                    }}
                  >
                    Click to Add
                  </span>
                </div>
              )}
              label={"color"}
              important={true}
              cache={true}
              options={getDropdownOptions("color", {
                is_led: true,
              })}
              // onInputChange={(newInput) => {
              //   const inputValue = newInput.replace(/\B\s+\B/g, "");
              //   return inputValue;
              // }}
              closeMenuOnSelect={false}
              onChange={(e) => {
                setColor(e);
              }}
              value={color}
              isMulti={true}
              required={true}
              feedback={{ type: "invalid", message: "Please select colors." }}
              isInvalid={validated && !color.length}
            />
            <DropdownControl
              as={Col}
              lg="4"
              md="6"
              sm="12"
              key={"ports" === refreshDropdown ? refreshDropdown : "ports-key"}
              noOptionsMessage={() => (
                <div className="dropdown-no-option-action" onClick={() => callForComponentData("ports")}>
                  Option is not available.
                  <br />
                  <span
                    style={{
                      color: "blue",
                      fontStyle: "italic",
                    }}
                  >
                    Click to Add
                  </span>
                </div>
              )}
              label={"Ports"}
              important={true}
              cache={true}
              options={getDropdownOptions("ports", {
                is_led: true,
              })}
              // onInputChange={(newInput) => {
              //   const inputValue = newInput.replace(/\W/g, "");
              //   return inputValue;
              // }}
              closeMenuOnSelect={false}
              onChange={(e) => {
                setPorts(e);
              }}
              value={ports}
              isMulti={true}
              required={true}
              feedback={{ type: "invalid", message: "Please select Ports." }}
              isInvalid={validated && !ports.length}
            />
            <DropdownControl
              as={Col}
              lg="4"
              md="6"
              sm="12"
              key={"speaker" === refreshDropdown ? refreshDropdown : "speaker-key"}
              noOptionsMessage={() => (
                <div className="dropdown-no-option-action" onClick={() => callForComponentData("speaker")}>
                  Option is not available.
                  <br />
                  <span
                    style={{
                      color: "blue",
                      fontStyle: "italic",
                    }}
                  >
                    Click to Add
                  </span>
                </div>
              )}
              label={"Speaker"}
              important={true}
              cache={true}
              options={getDropdownOptions("speaker", {})}
              // onInputChange={(newInput) => {
              //   const inputValue = newInput.replace(/\W/g, "");
              //   return inputValue;
              // }}
              onChange={(e) => {
                setSpeaker(e);
              }}
              value={speaker}
              required={true}
              feedback={{ type: "invalid", message: "Please select Speaker" }}
              isInvalid={validated && !speaker}
            />
            <DropdownControl
              as={Col}
              lg="4"
              md="6"
              sm="12"
              key={"resolution" === refreshDropdown ? refreshDropdown : "resolution-key"}
              noOptionsMessage={() => (
                <div className="dropdown-no-option-action" onClick={() => callForComponentData("resolution")}>
                  Option is not available.
                  <br />
                  <span
                    style={{
                      color: "blue",
                      fontStyle: "italic",
                    }}
                  >
                    Click to Add
                  </span>
                </div>
              )}
              label={"Screen Resolution(in Pixels)"}
              important={true}
              cache={true}
              options={getDropdownOptions("resolution", {})}
              // onInputChange={(newInput) => {
              //   const inputValue = newInput.replace(/\B\s+\B/g, "");
              //   return inputValue;
              // }}
              onChange={(e) => {
                setResolution(e);
              }}
              value={resolution}
              required={true}
              feedback={{ type: "invalid", message: "Please select Screen Resolution." }}
              isInvalid={validated && !resolution}
            />

            <DropdownControl
              as={Col}
              lg="4"
              md="6"
              sm="12"
              key={"refresh_rate" === refreshDropdown ? refreshDropdown : "refresh_rate-key"}
              noOptionsMessage={() => (
                <div className="dropdown-no-option-action" onClick={() => callForComponentData("refresh_rate")}>
                  Option is not available.
                  <br />
                  <span
                    style={{
                      color: "blue",
                      fontStyle: "italic",
                    }}
                  >
                    Click to Add
                  </span>
                </div>
              )}
              label={"Refresh Rate(in Hz)"}
              important={true}
              cache={true}
              options={getDropdownOptions("refresh_rate", {})}
              onChange={(e) => {
                setRefreshRate(e);
              }}
              value={refreshRate}
              required={true}
              feedback={{ type: "invalid", message: "Please select Refresh Rate." }}
              isInvalid={validated && !refreshRate}
            />

            <DropdownControl
              as={Col}
              lg="4"
              md="6"
              sm="12"
              key={"screen_size" === refreshDropdown ? refreshDropdown : "screen_size-key"}
              noOptionsMessage={() => (
                <div className="dropdown-no-option-action" onClick={() => callForComponentData("screen_size")}>
                  Option is not available.
                  <br />
                  <span
                    style={{
                      color: "blue",
                      fontStyle: "italic",
                    }}
                  >
                    Click to Add
                  </span>
                </div>
              )}
              label={"Screen Size(in Inches)"}
              important={true}
              cache={true}
              options={getDropdownOptions("screen_size", {})}
              // onInputChange={(newInput) => {
              //   const inputValue = newInput.replace(/[^\w.]/g, "");
              //   return inputValue;
              // }}
              onChange={(e) => {
                setScreenSize(e);
              }}
              value={screenSize}
              required={true}
              feedback={{ type: "invalid", message: "Please select Screen Size." }}
              isInvalid={validated && !screenSize}
            />

            <DropdownControl
              as={Col}
              lg="4"
              md="6"
              sm="12"
              key={"screen_type" === refreshDropdown ? refreshDropdown : "screen_type-key"}
              noOptionsMessage={() => (
                <div className="dropdown-no-option-action" onClick={() => callForComponentData("screen_type")}>
                  Option is not available.
                  <br />
                  <span
                    style={{
                      color: "blue",
                      fontStyle: "italic",
                    }}
                  >
                    Click to Add
                  </span>
                </div>
              )}
              label={"Screen Type"}
              important={true}
              cache={true}
              options={getDropdownOptions("screen_type", {})}
              // onInputChange={(newInput) => {
              //   const inputValue = newInput.replace(/\B\s+\B/g, "");
              //   return inputValue;
              // }}
              onChange={(e) => {
                setScreenType(e);
              }}
              value={screenType}
              required={true}
              feedback={{ type: "invalid", message: "Please select Screen Type." }}
              isInvalid={validated && !screenType}
            />

            <DropdownControl
              as={Col}
              lg="4"
              md="6"
              sm="12"
              key={"display_type" === refreshDropdown ? refreshDropdown : "display_type-key"}
              noOptionsMessage={() => (
                <div className="dropdown-no-option-action" onClick={() => callForComponentData("display_type")}>
                  Option is not available.
                  <br />
                  <span
                    style={{
                      color: "blue",
                      fontStyle: "italic",
                    }}
                  >
                    Click to Add
                  </span>
                </div>
              )}
              label={"Display Type"}
              important={true}
              cache={true}
              options={getDropdownOptions("display_type", {})}
              // onInputChange={(newInput) => {
              //   const inputValue = newInput.replace(/\W/g, "");
              //   return inputValue;
              // }}
              onChange={(e) => {
                setDisplayType(e);
              }}
              value={displayType}
              required={true}
              feedback={{ type: "invalid", message: "Please select Display Type." }}
              isInvalid={validated && !displayType}
            />

            <DropdownControl
              as={Col}
              lg="4"
              md="6"
              sm="12"
              label={"Webcam"}
              important={true}
              cache={true}
              isSearchable={false}
              options={() => {
                return new Promise((resolve, reject) => {
                  resolve([
                    {
                      value: true,
                      label: "Yes",
                    },
                    {
                      value: false,
                      label: "No",
                    },
                  ]);
                });
              }}
              onChange={(e) => {
                setWebcam(e);
              }}
              value={webcam}
              required={true}
              feedback={{ type: "invalid", message: "Please select Webcam." }}
              isInvalid={validated && !webcam}
            />
            <DropdownControl
              as={Col}
              lg="4"
              md="6"
              sm="12"
              label={"Smart TV"}
              important={true}
              cache={true}
              isSearchable={false}
              options={() => {
                return new Promise((resolve, reject) => {
                  resolve([
                    {
                      value: true,
                      label: "Yes",
                    },
                    {
                      value: false,
                      label: "No",
                    },
                  ]);
                });
              }}
              onChange={(e) => {
                setSmartTv(e);
                if (!e.value) {
                  setOperatingSystem("");
                  setCertification({ value: false, label: "No" });
                }
              }}
              value={smartTv}
              required={true}
              feedback={{ type: "invalid", message: "Please select Smart TV." }}
              isInvalid={validated && !smartTv}
            />
            {smartTv.value && (
              <>
                <DropdownControl
                  as={Col}
                  lg="4"
                  md="6"
                  sm="12"
                  key={"operating_system" === refreshDropdown ? refreshDropdown : "operating_system-key"}
                  noOptionsMessage={() => (
                    <div className="dropdown-no-option-action" onClick={() => callForComponentData("operating_system")}>
                      Option is not available.
                      <br />
                      <span
                        style={{
                          color: "blue",
                          fontStyle: "italic",
                        }}
                      >
                        Click to Add
                      </span>
                    </div>
                  )}
                  label={"Operating System"}
                  important={true}
                  cache={true}
                  options={getDropdownOptions("operating_system", {})}
                  // onInputChange={(newInput) => {
                  //   const inputValue = newInput.replace(/\W/g, "");
                  //   return inputValue;
                  // }}
                  onChange={(e) => {
                    setOperatingSystem(e);
                  }}
                  value={operatingSystem}
                  required={true}
                  feedback={{ type: "invalid", message: "Please select Operating System." }}
                  isInvalid={validated && !operatingSystem}
                />
                <DropdownControl
                  as={Col}
                  lg="4"
                  md="6"
                  sm="12"
                  label={"Certification"}
                  important={true}
                  cache={true}
                  isSearchable={false}
                  options={() => {
                    return new Promise((resolve, reject) => {
                      resolve([
                        {
                          value: true,
                          label: "Yes",
                        },
                        {
                          value: false,
                          label: "No",
                        },
                      ]);
                    });
                  }}
                  onChange={(e) => {
                    setCertification(e);
                  }}
                  value={certification}
                  required={true}
                  feedback={{ type: "invalid", message: "Please select Certification." }}
                  isInvalid={validated && !smartTv}
                />
              </>
            )}
            <Form.Group as={Col} lg="12" md="12" sm="12" className="mb-3" controlId="description">
              <Form.Label className="asterik-label">Description:</Form.Label>
              <Markdown value={description} setValue={setDescription} setText={setDescriptionText} placeholder="Enter Description (5,000 Characters)" />
              <Form.Control
                hidden
                as="textarea"
                placeholder="Enter Description (5,000 Characters)"
                value={descriptionText.trim()}
                onChange={(e) => {
                  setDescription(e.target.value);
                }}
                maxLength={5000}
                required
              />
              <Form.Control.Feedback type="invalid">Please Enter the description.</Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} lg="12" md="12" sm="12" className="mb-3" controlId="submit-button">
              <Form.Control type="button" value={mode === "edit" ? "Edit" : "Add"} onClick={submitForm} className="btn btn-primary text-capitalize" />
            </Form.Group>
          </Row>
        </Form>
      </AddForm>
      <PopUpModal size="md" title={`Add ${componentData.name}`} show={showAddComponentModal} onHide={() => setShowAddComponentModal(false)} isLoading={isModalLoading}>
        <AddComponent close={() => setShowAddComponentModal(false)} toggle={toggle} fieldData={componentData} />
      </PopUpModal>
    </>
  );
};

export default TvMonitorsFormPage;
